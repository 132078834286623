.Main {
	z-index: 2;
	/* position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px; */

	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100vh;
	background-color: #ffffff00;
}

.Logo {
	z-index: 2;
	background-color: #1d5d9b;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;

	top: 0px;
}

.LogoImage {
	width: 40px;
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.LogoText {
	margin: 0;
	padding: 0;
	color: #ffffff;
	font-size: 16px;
	line-height: 16px;
}

.LogoDiv {
	padding: 10px;
}

.Bottom {
	z-index: 3;
	background-color: #1e1e1e00;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	position: absolute;
	bottom: 0px;
}

.BottomButton {
	font-family: 'Montserrat';
	background-color: transparent;
	padding: 2px 20px;
	transition-duration: 0s;
	cursor: pointer;
	color: black;
	margin-left: 10px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
	border: 0px solid black;
	border-bottom: 1px solid black;
	height: 28px;
	width: 110px;
}

.BottomButton:hover {
	background: linear-gradient(0deg, #d6eaf8, transparent);
	border-bottom: 2px solid black;
	color: 0x000000;
	font-weight: 900;
	font-size: 18px;
}

.Button {
	font-family: 'Montserrat';
	background-color: #1d5d9b;
	padding: 6px;
	transition-duration: 0.1s;
	cursor: pointer;
	margin: 0px;
	color: white;
	font-size: 24px;
	font-weight: 900;
	border: 0px solid black;
	border-radius: 400px;
	height: 50px;
	width: 200px;
}

.Button:hover {
	border-bottom: 5px solid #1d5d9b;
	background-color: #75c2f6;

	color: 0x000000;
	font-weight: 900;
}

.Line {
	width: 700px;
	margin-top: 50px;
	margin-bottom: 10px;
}

@media (max-width: 440px) {
	.ButtonsContainer {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
}

.BlockScroll {
	overflow: hidden;
	width: 100%;
}

.Circles {
	position: absolute;
	z-index: 1;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	overflow: hidden;
	width: 100%;
	height: 100vh;
}

.Circle1 {
	z-index: 1;
	width: 210px;
	height: 210px;
	background: #f4d160;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	margin: auto;
	animation: circle1 14s linear infinite;
}

@keyframes circle1 {
	0% {
		transform: rotate(0deg) translate(-350px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-350px) rotate(-360deg);
	}
}

.Circle2 {
	z-index: 1;
	width: 160px;
	height: 160px;
	background: #f4d160;
	border-radius: 50%;
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	margin: auto;
	animation: circle2 30s linear infinite;
}

@keyframes circle2 {
	0% {
		transform: rotate(0deg) translate(-390px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-390px) rotate(-360deg);
	}
}

.Circle3 {
	z-index: 1;
	width: 280px;
	height: 280px;
	background: #f4d160;
	border-radius: 50%;
	border: 1px solid #00000010;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	animation: circle3 19s linear infinite;
}

@keyframes circle3 {
	0% {
		transform: rotate(0deg) translate(-120px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-120px) rotate(-360deg);
	}
}

.Circle1Moon1 {
	z-index: 2;
	width: 52px;
	height: 52px;
	background: #fbeeac;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	overflow: hidden;
	margin: auto;
	animation: circle1Moon1 7s linear infinite;
}

@keyframes circle1Moon1 {
	0% {
		transform: rotate(0deg) translate(-240px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-240px) rotate(-360deg);
	}
}

.Circle2Moon1 {
	z-index: 2;
	width: 30px;
	height: 30px;
	background: #fbeeac;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	overflow: hidden;
	margin: auto;
	animation: circle2Moon1 8s linear infinite;
}

@keyframes circle2Moon1 {
	0% {
		transform: rotate(0deg) translate(-120px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-120px) rotate(-360deg);
	}
}

.Circle2Moon2 {
	z-index: 2;
	width: 47px;
	height: 47px;
	background: #fbeeac;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	overflow: hidden;
	margin: auto;
	animation: circle2Moon2 12s linear infinite;
}

@keyframes circle2Moon2 {
	0% {
		transform: rotate(0deg) translate(-180px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-180px) rotate(-360deg);
	}
}

.Circle3Moon1 {
	z-index: 2;
	width: 70px;
	height: 70px;
	background: #fbeeac;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #00000010;
	overflow: hidden;
	margin: auto;
	animation: circle3Moon1 17s linear infinite;
}

@keyframes circle3Moon1 {
	0% {
		transform: rotate(0deg) translate(-350px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-350px) rotate(-360deg);
	}
}
