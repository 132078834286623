.Main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Line {
	width: 700px;
	margin: 15px;
}

.Project {
	background: linear-gradient(#fbeeac30, #9198e500);
	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 15px;
	border-top: 2px solid #fbeeac;
	width: 1000px;
	margin: 15px;
	padding: 20px;
}

.ProjectImage {
	height: 200px;
}

.Button {
	font-family: 'Montserrat';
	background-color: #1d5d9b;
	padding: 6px;
	transition-duration: 0.1s;
	cursor: pointer;
	margin: 15px;
	color: white;
	font-size: 24px;
	font-weight: 900;
	border: 0px solid black;
	border-radius: 400px;
	height: 50px;
	width: 200px;
}

.Button:hover {
	border-bottom: 5px solid #1d5d9b;
	background-color: #75c2f6;

	color: 0x000000;
	font-weight: 900;
}
