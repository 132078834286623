.Line {
	width: 700px;
	height: 50px;
}

.Bg {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-image: url('./bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.Main {
	z-index: 2;
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
}

.GamesDiv {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.Logo {
	margin-top: 50px;
	height: 110px;
}

.Game {
	margin: 20px;
}

.GameImage {
	border: 3px solid #1d5d9b;
	border-radius: 15px;
	object-fit: fill;
	width: 400px;
	height: 250px;
}

.GameImage:hover {
	border: 3px solid #fef8f8;
	cursor: pointer;
}

@media (max-width: 440px) {
	.Game {
		width: 280px;
		height: 175px;
	}

	.GameImage {
		width: 280px;
		height: 175px;
	}
}
