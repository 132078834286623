.Logo {
	z-index: 100;
	background-color: #1d5d9b;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;

	top: 0px;
}

.LogoImage {
	width: 40px;
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.LogoText {
	margin: 0;
	padding: 0;
	color: #ffffff;
	font-size: 16px;
	line-height: 16px;
}

.LogoDiv {
	padding: 10px;
}

.BackButton {
	background-color: transparent;
	position: absolute;
	cursor: pointer;
	margin: 0px;
	color: white;
	left: 0px;
	top: 0px;
	font-weight: 900;
	border: 0px solid white;
	border-radius: 400px;
}

@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 100 700;
	src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
		format('woff2');
}

.materialSymbolsOutlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 33px;
	color: white;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
}
