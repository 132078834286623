.Main {
	z-index: 2;
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding-top: 70px;
	padding-bottom: 20px;
}

.Container {
	z-index: 2;
}

.Profile {
	z-index: 3;
	display: flex;
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #ffffff00;
}

.Logo {
	z-index: 10;
	background-color: #1e1e1e;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0px;
}

@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 100 700;
	src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
		format('woff2');
}

.materialSymbolsOutlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 50px;
	color: #f4d160;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
}
.ProfileImage {
	width: 220px;
	height: 220px;
	border: 1px solid #494949;
	border-bottom: 5px solid #494949;
	border-radius: 200px;
	margin-right: 30px;
}

.LogoImage {
	width: 40px;
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.LogoText {
	margin: 0;
	padding: 0;
	color: #ced000;
	font-size: 16px;
	line-height: 16px;
}

.LogoDiv {
	padding: 10px;
}

.BackButton {
	background-color: transparent;
	position: absolute;
	cursor: pointer;
	margin: 0px;
	color: white;
	font-size: 30px;
	left: 0px;
	top: 0px;
	font-weight: 900;
	border: 0px solid white;
	border-radius: 400px;
}

.Bottom {
	z-index: 3;
	background-color: #1e1e1e00;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	position: absolute;
	bottom: 0px;
}

.BottomButton {
	font-family: 'Montserrat';
	background-color: #f8f9f9;
	padding: 2px 20px;
	transition-duration: 0s;
	cursor: pointer;
	color: black;
	margin-left: 10px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
	border: 0px solid black;
	border-bottom: 1px solid black;
	height: 28px;
	width: 110px;
}

.BottomButton:hover {
	background: linear-gradient(0deg, #d6eaf8, #f8f9f9);
	border-bottom: 2px solid black;
	color: 0x000000;
	font-weight: 900;
	font-size: 18px;
}

.Button {
	font-family: 'Montserrat';
	background-color: #f2f4f4;
	padding: 6px;
	transition-duration: 0.1s;
	cursor: pointer;
	margin: 0px;
	color: black;
	font-size: 24px;
	font-weight: 900;
	border: 1px solid black;
	border-radius: 400px;
	height: 60px;
	width: 200px;
}

.Button:hover {
	border-bottom: 5px solid #494949;
	background-color: #d6eaf8;

	color: 0x000000;
	font-weight: 900;
}

.InfoDiv {
	margin-bottom: 15px;
}

.InfoText {
	text-align: center;
	margin: 0px;
}

.Line {
	z-index: 1;
	position: relative;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

@media (max-height: 640px) {
	.Main {
		z-index: 2;
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: 0px;
		left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow: auto;
	}
}

@media (max-width: 580px) {
	.Profile {
		display: flex;
		flex-direction: row;
	}

	.ProfileImage {
		margin-right: 0px;
	}

	.Main {
		z-index: 2;
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: 0px;
		left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow: auto;
	}
}

.Animation {
	z-index: 1;
	transform: translate(0, -42%);
	left: -15px;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	overflow: hidden;
}

.line1,
.line2,
.line3,
.line4 {
	stroke-dasharray: 2500;
	stroke-dashoffset: 2500;
	animation: dash 8s linear forwards;
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}
