.body {
	height: auto;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #fffbeb;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: black;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 10px 10px 10px #75c2f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #1d5d9b;

	border: 2px solid #75c2f6;
	border-radius: 5px;
	box-shadow: inset 0px 0px 0px #75c2f6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	border: 1px solid #75c2f6;
}

