@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

html,
body {
	background-color: #f8f9f9;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
	font-size: 44px;
	font-family: 'Montserrat', sans-serif;
	text-align: center;
	white-space: pre-line;
}

h2 {
	font-size: 28px;
	font-family: 'Montserrat', sans-serif;
	text-align: center;
	white-space: pre-line;
}

h3 {
	font-family: 'Montserrat', sans-serif;
	text-align: center;
	margin: 10px;
	white-space: pre-line;
}

p {
	font-family: 'Montserrat', sans-serif;
	font-size: 20px;
	white-space: pre-line;
}

