.Main {
	z-index: 2;
	position: absolute;
	top: 0px;
	bottom: 0px;
	right: 0px;
	left: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding-top: 20px;
	padding-bottom: 20px;
}

.Container {
	z-index: 2;
}

.InfoContainer {
	z-index: 2;
	max-width: 800px;
	margin: 15px;
}

.InfoText {
	text-align: justify;
	text-indent: 70px;
}

.Logo {
	z-index: 10;
	background-color: #1e1e1e;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0px;
}

.LogoImage {
	width: 40px;
	margin-left: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.LogoText {
	margin: 0;
	padding: 0;
	color: #ced000;
	font-size: 16px;
	line-height: 16px;
}

.BackButton {
	background-color: transparent;
	position: absolute;
	cursor: pointer;
	margin: 0px;
	color: white;
	font-size: 30px;
	left: 0px;
	top: 0px;
	font-weight: 900;
	border: 0px solid white;
	border-radius: 400px;
}

@font-face {
	font-family: 'Material Symbols Outlined';
	font-style: normal;
	font-weight: 100 700;
	src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
		format('woff2');
}

.materialSymbolsOutlined {
	font-family: 'Material Symbols Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 50px;
	color: #ced000;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: 'Material Symbols Filled';
	src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v75/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzazHD_dY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOej.woff2)
		format('woff2');
}

.Wheels {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100vh;
}

.SettingsWheelSymbol1 {
	font-family: 'Material Symbols Filled';
	font-weight: 100;
	font-size: 180px;

	color: #f4d160;
}

.SettingsWheel1 {
	position: absolute;
	z-index: 1;
	bottom: 100px;
	right: 100px;
	border: 8px solid #fbeeac;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 0px;
	height: 0px;
	animation: geometric-form-animation 4s linear infinite;
}

.SettingsWheelSymbol2 {
	font-family: 'Material Symbols Filled';
	font-weight: 100;
	font-size: 130px;

	color: #fbeeac;
}

.SettingsWheel2 {
	position: absolute;
	z-index: 1;
	bottom: 215px;
	right: 75px;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 0px;
	height: 0px;
	animation: geometric-form-animation-rev 4s linear infinite;
}

.SettingsWheelSymbol3 {
	font-family: 'Material Symbols Filled';
	font-weight: 100;
	font-size: 110px;

	color: #fbeeac;
}

.SettingsWheel3 {
	position: absolute;
	z-index: 1;
	bottom: 80px;
	right: 212px;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 0px;
	height: 0px;
	animation: geometric-form-animation-rev 4s linear infinite;
	animation-delay: 0.1s;
}

.SettingsWheelSymbol4 {
	font-family: 'Material Symbols Filled';
	font-weight: 100;
	font-size: 210px;

	color: #f4d160;
}

.SettingsWheel4 {
	position: absolute;
	z-index: 1;
	top: 160px;
	left: 85px;
	border: 10px solid #fbeeac;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 0px;
	height: 0px;
	animation: geometric-form-animation 6s linear infinite;
}

.SettingsWheelSymbol5 {
	font-family: 'Material Symbols Filled';
	font-weight: 100;
	font-size: 170px;

	color: #fbeeac;
}

.SettingsWheel5 {
	position: absolute;
	z-index: 1;
	top: 282px;
	left: 187px;
	justify-content: center;
	align-items: center;
	display: flex;
	width: 0px;
	height: 0px;
	animation: geometric-form-animation-rev 6s linear infinite;
	animation-delay: 0.25s;
}

@keyframes geometric-form-animation-rev {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@keyframes geometric-form-animation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.TechContainer {
	z-index: 2;
	--gap: 1rem;
	position: relative;
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: var(--gap);
	height: 220px;
}

.TechContainerContent {
	flex-shrink: 0;
	display: flex;
	justify-content: space-around;
	gap: var(--gap);
	min-width: 100%;
	animation: scroll 20s linear infinite;
}

.TechImgContainer {
	background: linear-gradient(0deg, #d6eaf890, #f8f9f9);
	border-radius: 20px;
	border-bottom: 3px solid #1e1e1e99;
	height: 195px;
	width: 240px;
	margin-top: 15px;
	margin-bottom: 15px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
	transition-duration: 70ms;
}

.TechImgContainer:hover {
	background: linear-gradient(0deg, #d6eaf8, #f8f9f9);
	border-bottom: 7px solid #1e1e1e;
}

.TechImg {
	object-fit: contain;
	height: 150px;
	width: 170px;
}

@keyframes scroll {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-100% - var(--gap)));
	}
}

.TechContainer:hover .TechContainerContent {
	animation-play-state: paused;
}

.ThreeJs {
	margin: 0px;
	padding: 0px;
}

.ReactJs {
	font-family: Arial, Helvetica, sans-serif;
	color: #61dafb;
	font-weight: '900';
	margin: 0px;
	padding: 0px;
}

@media (max-height: 640px) {
	.Main {
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow: auto;
		padding-top: 70px;
	}
}

@media (max-width: 580px) {
	.Main {
		z-index: 2;
		top: 0px;
		bottom: 0px;
		right: 0px;
		left: 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow: auto;
		padding-top: 70px;
	}
}
